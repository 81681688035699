<template>
    <div class="loader">
        <div class="spin"></div>
    </div>
</template>

<script>
export default {
    name: 'Spinner'
}
</script>

<style scoped>
.loader {
    display: flex;
    align-items: center;
    justify-content: center;
}
.spin {
    border: 3px solid #f3f3f3;
    border-top: 3px solid #3498db;
    border-radius: 50%;
    width: 3rem;
    height: 3rem;
    animation: spin 2s linear infinite;
}

@keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
}
</style>