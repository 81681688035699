<template>
    <div class="actions">
        <div>
            <button @click="checkAll" class="btn-action">
                <FontAwesomeIcon :icon="['far', 'square']" v-if="!anyChecked"/>
                <FontAwesomeIcon :icon="['far', 'check-square']" v-if="anyChecked"/>
                All
            </button>
            <div class="dropdown" @mouseup.stop v-show="anyChecked">
                <button @click="toggleLabels" class="btn-action">
                    <FontAwesomeIcon icon="tag"/> Label
                </button>
                <div v-if="dropdown == 'label'" class="dropdown-menu">
                    <ul class="dropdown-list">
                        <li v-for="label in $store.state.labels" :key="label.id">
                            <button class="dropdown-item" @click="labelPhotos(label)">{{ label.name }}</button>
                        </li>
                        <li>
                            <button class="dropdown-item" @click="removeLabels()">Remove labels</button>
                        </li>
                    </ul>
                </div>
            </div>
            <div class="dropdown" @mouseup.stop v-show="anyChecked">
                <button @click="toggleGroups" class="btn-action">
                    <FontAwesomeIcon icon="users"/> Groups
                </button>
                <div v-if="dropdown == 'group'" class="dropdown-menu">
                    <ul class="dropdown-list">
                        <li v-for="group in $store.state.groups" :key="group.id">
                            <button class="dropdown-item" @click="addToGroup(group)">{{ group.name }}</button>
                        </li>
                    </ul>
                </div>
            </div>
            <button @click="deletePhotos" class="btn-action" v-show="anyChecked">
                <FontAwesomeIcon icon="trash-alt"/> Delete
            </button>
        </div>
        <div>
            <div v-show="anyChecked" class="count">{{ checked.length }} photo{{ checked.length > 1 ? 's' : '' }} selected</div>
            <button @click="$emit('toggle-drag')" class="btn-action" :class="{active: dragging}">
                <FontAwesomeIcon icon="arrows-alt"/> {{ dragging ? 'Reordering' : 'Reorder' }}
            </button>
        </div>
    </div>
</template>

<script>
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'

export default {
    name: 'Actions',
    data() {
        return {
            dropdown: null,
        }
    },
    computed: {
        checked() {
            return this.photos.filter(photo => photo.checked)
        },
        anyChecked() {
            return this.checked.length > 0
        }
    },
    props: {
        photos: Array,
        dragging: Boolean
    },
    components: {
        FontAwesomeIcon
    },
    methods: {
        labelPhotos(label) {
            this.axios.post('admin/photos/label/add/', {label: label.id, photos: this.checked.map(photo => photo.id)})
            .then(() => {
                this.checked.forEach(photo => {
                    photo.category = label.name
                    photo.checked = false
                })
                this.dropdown = null
            })
            .catch(() => {})
        },
        removeLabels() {
            this.axios.post('admin/photos/label/remove/', {photos: this.checked.map(photo => photo.id)})
            .then(() => {
                this.checked.forEach(photo => photo.category = null)
                this.dropdown = null
            })
            .catch(() => {})
        },
        deletePhotos() {
            this.axios.post('admin/photos/delete/', {photos: this.checked.map(photo => photo.md5)})
            .then(() => {
                this.$emit('delete-photos', this.checked)
            })
            .catch(() => {})
        },
        addToGroup(group) {
            this.axios.post('admin/group/photos/add/', {group: group.id, photos: this.checked.map(photo => photo.id)})
            .then(resp => {
                this.dropdown = null
                group.count = resp.data.total
                this.checked.forEach(photo => photo.checked = false)
            })
            .catch(() => {})
        },
        checkAll() {
            const anyChecked = this.anyChecked
            this.photos.forEach(photo => {
                photo.checked = !anyChecked
            })
        },
        toggleLabels() {
            if (this.dropdown == 'label') {
                this.dropdown = null
            }
            else {
                this.dropdown = 'label'
            }
        },
        toggleGroups() {
            if (this.dropdown == 'group') {
                this.dropdown = null
            }
            else {
                this.dropdown = 'group'
            }
        },
        closeDropdowns() {
            this.dropdown = null
        }
    },
    created() {
        window.addEventListener('mouseup', this.closeDropdowns)
    },
    beforeDestroy() {
        window.removeEventListener('mouseup', this.closeDropdowns)
    }
}
</script>

<style scoped>
.actions {
    position: sticky;
    top: 3.5rem;
    z-index: 1;
    background-color: white;
    padding: .5rem 1rem;
    border-bottom: 1px solid #ddd;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
}
.actions-checked {
    display: inline-block;
}
.btn-action {
    background: none;
	color: inherit;
	border: none;
	font: inherit;
	cursor: pointer;
    padding: 0;
    padding: .25rem .5rem;
    margin: .25rem .5rem .25rem 0;
}
.btn-action.active {
    color: green;
    background-color: #cfffe5;
    border-radius: .5rem;
}
.dropdown {
    display: inline-block;
    position: relative;
}
.dropdown-menu {
    position: absolute;
    top: 2rem;
    left: 0;
    min-width: 200px;
    z-index: 2;
    background-color: white;
    border: 1px solid gray;
    border-radius: .5rem;
    box-shadow: 0 .5rem 1rem rgba(0, 0, 0, .15);
    overflow: hidden;
}
.dropdown-list {
    padding: 0;
    margin: 0;
    list-style: none;
}
.dropdown-list>li:hover {
    background-color: #eee;
}
.dropdown-item {
    background: none;
	color: inherit;
	border: none;
	font: inherit;
	cursor: pointer;
	outline: inherit;
    width: 100%;
    text-align: left;
    padding: .5rem 1rem;
}
.dropdown-list>li:last-child:not(:first-child) {
    border-top: 1px solid gray;
}
.count {
    display: inline-block;
    padding: .25rem .5rem;
    margin: .25rem 0;
}
</style>