<template>
    <div @click="photo.checked = !photo.checked" :class="{drag: draggable}" class="photo">
        <template v-if="!photo.loading">
            <input type="checkbox" v-model="photo.checked" class="photo-check">
            <span v-if="photo.category" class="label">{{ photo.category }}</span>
            <img :src="photo.uri.sm">
        </template>
        <Spinner v-else class="loader"></Spinner>
    </div>
</template>

<script>
import Spinner from '@/components/Spinner'

export default {
    name: 'Photo',
    props: {
        photo: Object,
        draggable: Boolean
    },
    components: {
        Spinner
    }
}
</script>

<style scoped>
.photo {
    position: relative;
    max-height: 150px;  
    padding: .5rem;
    flex-basis: 12.5%;
    border-radius: .5rem;
    cursor: pointer;
}
.photo.drag {
    cursor: grab;
}
.photo:hover {
    background-color: #ddd;
}
.photo-check {
    background-color: white;
    position: absolute;
    top: 0;
    left: 0;
}
.loader {
    height: 100%;
}
.label {
    position: absolute;
    top: 0;
    right: 0;
    background-color: #cfffe5;
    border: 1px solid #98ff98;
    font-size: .7rem;
    padding: .1rem .5rem;
    border-radius: .5rem;
    max-width: 75%;
}
img {
    display: block;
    max-height: 100%;
    max-width: 100%;
    margin-left: auto;
    margin-right: auto;
}
@media (max-width: 1024px) {
    .photo {
        flex-basis: 20%;
    }
}
@media (max-width: 768px) {
    .photo {
        flex-basis: 25%;
    }
}
@media (max-width: 480px) {
    .photo {
        flex-basis: 50%;
    }
}
</style>